import React, { useState, useEffect } from 'react';
import "./App.css";
import image from "./images/mynaui_download.svg";
import checkmark from "./images/eva_checkmark-outline.svg";
import back from "./images/lets-icons_refund-back.svg";
import Background from "./Components/background/background";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import imageCompression from 'browser-image-compression';

const API_URL = "https://dokalab.com/api";

const uploadImage = async (imageData) => {
  try {
    const response = await axios.post(`${API_URL}/photoupload1`, imageData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

const getImage = async () => {
  try {
    const response = await axios.get(`${API_URL}/pictures`, {
      responseType: 'arraybuffer'
    });
    const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
};

const deleteImage = async () => {
  try {
    const response = await axios.delete(`${API_URL}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};

function Upload() {
  const [flag, setFlag] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(selectedFile, options);
        console.log('compressedFile size', compressedFile.size / 1024 / 1024, 'MB');

        const formData = new FormData();
        formData.append('file', compressedFile);

        await uploadImage(formData);
        setFile(compressedFile);
        setFlag(true);
      } catch (error) {
        console.error('Error uploading image: ', error);
      }
    }
  };

  const handleBackClick = () => {
    setFlag(false);
    setFile(null);
  };

  return (
    <div className={`container ${flag ? "uploaded" : ""}`}>
      {!flag ? (
        <div className="upload-image">
          <h2 className="form-title">
            Click on the button below to upload your image
          </h2>
          <div className="form-container">
            <form method="post" encType="multipart/form-data">
              <label htmlFor="file-upload" className="custom-file-upload">
                <span>Add your image</span>
                <img src={image} alt="icon-upload" />
              </label>
              <input
                type="file"
                id="file-upload"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
            </form>
          </div>
        </div>
      ) : (
        <div className="successful-upload">
          <div className="logo">
            <img src={checkmark} alt="Upload successful" />
          </div>
          <h2>Your image has been successfully uploaded</h2>
          <button className="back-btn" onClick={handleBackClick}>
            <span>Back</span>
            <img src={back} alt="Back" />
          </button>
        </div>
      )}
    </div>
  );
}

function Pictures() {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageURL = await getImage();
        setImage(imageURL);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <div className="Pictures">
      <h2 className="form-title">Your uploaded image</h2>
      {image ? (
        <img src={image} alt="Uploaded" className="uploaded-image" />
      ) : (
        <p className="custom-file-upload">No image uploaded.</p>
      )}
    </div>
  );
}

function Delete() {
  const handleDelete = async () => {
    try {
      await deleteImage();
      alert("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <div className="Delete">
      <h2 className="form-title">Delete your image</h2>
      <button className="custom-file-upload" onClick={handleDelete}>
        Delete Image
      </button>
    </div>
  );
}

function Main() {
  return (
    <div className="App">
      <Background />
      <div className="container">
        <Router>
          <Routes>
            <Route path="/" element={<Upload />} />
            <Route path="/photoupload1" element={<Upload />} />
            <Route path="/pictures" element={<Pictures />} />
            <Route path="/delete" element={<Delete />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default Main;
